import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import './style.css'
import { LoadingOutlined, DownloadOutlined, ExclamationCircleFilled } from '@ant-design/icons';
import DataTable from 'react-data-table-component'
// import { columns } from './constant'
import Breadcrumb from '../../../components/common/breadcrumb';
import { Link } from 'react-router-dom';
import { Button, Input, message, Switch } from 'antd';
import { getRequestApiByToken, postRequestApiByToken } from '../../../api';
import { debounce } from 'lodash';
import moment from 'moment';
import ViewProjectDetails from '../viewProjectDetails';
import { getQuerySelectorValueFormArray, handleDownloadFile } from '../../../utility';
import Modal from 'antd/lib/modal/Modal';
import Form from 'antd/lib/form/Form';
import ProjectSearchForm from './ProjectSearchForm';
import dayjs from 'dayjs';
const { Search } = Input;

const ProjectList = () =>  {
   const [advanceForm] = Form.useForm();
   const ls_userType = useSelector(content => content.commonReducer.user_type);
   const ls_userId = useSelector(content => content.commonReducer.user_id);
   const [la_projectList, setProjectList] = useState([])
   const [la_columnsList, setColumnsLis] = useState([])
   // const [totalPages, setTotalPages] = useState(0);
   const [totalCount, setTotalCount] = useState(0);
   const [currentPage, setCurrentPage] = useState(1);
   const [loading, setLoading] = useState(false);
   const [ls_switchLoading, setSwitchLoading] = useState(null);
   const [filterText, setFilterText] = useState('');

   const [lb_showProjectDetails, setShowProjectDetails] = useState(false)
   const [ls_projectId, setProjectId] = useState('');
   const [ls_formParamsData, setFormParamsData] = useState('');

   const [orderByField, setOrderByField] = useState('');
   const [orderDirection, setOrderDirection] = useState('asc');

   const handleSort = (column, sortDirection) => {
      setOrderByField(column.sortField);
      setOrderDirection(sortDirection);
   };

   const redirectToDocumentList = (row) => {
      if(!row?.mandate_no) return;
      console.log('---row', row);
      const redirectUrl = `/document/search-document?search_query=${row.mandate_no}`;
      window.open(redirectUrl, '_blank');
   }

   const columns = [
      {
         name: "Project Name",
         // selector: (row) => row.project_name,
         selector: (row) => (
            <div style={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>
               <Link onClick={() => { setShowProjectDetails(true); setProjectId(row.id); }}>{row.project_name}</Link>
            </div>
         ),
         sortable: true,
         sortField: 'project_name',
         sortFunction: (rowA, rowB) => rowA?.project_name?.localeCompare(rowB?.project_name),
         // center: true,
         // width: '200px', 
         // minWidth: '500px', 

      },
      {
         selector: (row) => <div>
            {(row?.documents_count > 0) ? <Link onClick={() => redirectToDocumentList(row) } style={{ cursor: 'pointer' }}>{row.documents_count}</Link> : row.documents_count}
         </div>,
         name: "No. Of Documents",
         sortable: true,
         sortField: 'documents_count',
         center: true,
         width: '80px'
      },
      {
         selector: (row) => row.mandate_no,
         name: "Project Code",
         sortable: true,
         sortField: 'mandate_no',
         center: true,
         width: '138px'
      },
      {
         selector: (row) => moment(row.start_date).format("DD MMM YYYY"),
         name: "Start Date",
         sortable: true,
         sortField: 'start_date',
         center: true,
         width: '110px'
      },
      {
         selector: (row) => moment(row.end_date).format("DD MMM YYYY"),
         name: "End Date",
         sortable: true,
         sortField: 'end_date',
         center: true,
         width: '110px'
      },
      {
         selector: (row) => <div>
            <span style={{ cursor: 'pointer' }}>
               <Link to="/projects/update" state={{ updateId: row.id }} > <i className="fa fa-pencil" style={{ width: 35, fontSize: 16, padding: 11, color: "rgb(40, 167, 69)" }}></i>
               </Link>
            </span>
            <Switch loading={ls_switchLoading == row?.id} defaultChecked={row.status} size='small' onChange={(s) => onChangeStatus(s, row)} />
            {row.documents_count == 0 ?
               <span>
                  <Link onClick={() => handleOnClickRemove(row)}><i className="fa fa-trash" style={{ width: 35, fontSize: 16, padding: 11, color: "#e4566e" }}></i></Link>
               </span> :
               <span style={{ width: 35, fontSize: 16, padding: 15 }}></span>}
         </div>,
         name: "Action",
         center: true,
         width: '130px'
      },
   ];

   const handleOnClickRemove = (row) => {
      Modal.confirm({
        title: 'Are you sure?',
        icon: <ExclamationCircleFilled />,
        content: 'You want to delete project?',
        onOk() {
          return new Promise((resolve, reject) => {
            removeProject(row, resolve, reject)
          }).catch(() => console.log('Oops errors!'));
        },
        onCancel() {},
      });
    };
    
    const removeProject = async (rowValue, resolve, reject) => {
      // setButtonLoading(true)
      const res = await postRequestApiByToken('deleteproject/'+rowValue?.id, {})
      if (res?.status == "Success") {
         getProjectList();
         message.success('Priject deleted successfully!');
         resolve()
       } else {
         reject()
       }
    };

   const onChangeStatus = async (checked, row) => {
      setSwitchLoading(row?.id || null);
      let lo_formData = { status: (checked == true ? 1 : 0), project_id: row.id }
      const res = await postRequestApiByToken('project_status_update', lo_formData)
      setSwitchLoading(null);
      if (res?.status == "Success") {
         getProjectList();
         message.success(`${row?.status == 1 ? 'Incativated' : 'Activated'} Successfully!`);
      }
   };

   const handleSearch = debounce((value) => {
      setFilterText(value);
   }, 300);
  
    // Filtered data based on search text
   //  const filteredData = la_projectList?.filter((item) =>
   //    Object.values(item).some(
   //      (value) => typeof value === 'string' && value.toLowerCase().includes(filterText.toLowerCase())
   //    )
   //  );

   const getProjectList = async () => {
      setLoading(true);
      
      // let ls_apiParams = `project?search=${filterText}&pagination=true&orderBy=desc&page=${currentPage}`;
      let ls_apiParams = `project?pagination=true&orderBy=desc&page=${currentPage}`;
      if(orderDirection && orderByField) ls_apiParams = ls_apiParams + `&sort_direction=${orderDirection}&sort_field=${orderByField}`;
      console.log('---ls_formParamsData--------------------------------',ls_formParamsData);
      if(ls_formParamsData) ls_apiParams = ls_apiParams+'&'+ls_formParamsData;
      const res = await getRequestApiByToken(ls_apiParams);
      setProjectList(res?.Projects?.data);
      setTotalCount(res?.Projects?.total);
      setLoading(false);
      console.log('getProjectList......>>>>>',res?.Projects?.data);
   }

   const handleSearchData = (formValue) => {
      console.log('---formValue', formValue);
      const lo_formData = JSON.parse(JSON.stringify(formValue));
      const lo_paramsValue = { ...lo_formData };
      if (lo_paramsValue?.start_date) lo_paramsValue['start_date'] = dayjs(new Date(lo_formData.start_date)).format('YYYY-MM-DD');
      if (lo_paramsValue?.end_date) lo_paramsValue['end_date'] = dayjs(new Date(lo_formData.end_date)).format('YYYY-MM-DD');
      if (formValue?.tot_value_range) {
         lo_paramsValue['tot_value_from'] = formValue?.tot_value_range[0];
         lo_paramsValue['tot_value_to'] = formValue?.tot_value_range[1];
      }
      if (formValue?.firm_value_range) {
         lo_paramsValue['firm_value_from'] = formValue?.firm_value_range[0];
         lo_paramsValue['firm_value_to'] = formValue?.firm_value_range[1];
      }

      delete lo_paramsValue['tot_value_range'];
      delete lo_paramsValue['firm_value_range'];
      
      
      const ls_params = getQuerySelectorValueFormArray(lo_paramsValue);
      console.log('++++++ls_params++++',lo_paramsValue, ls_params);
      setFormParamsData(ls_params);
      // setFormData(formValue);
      // setIsSearch(true)
   }
    
   useEffect(() => {
      getProjectList()
   }, [filterText, currentPage, ls_formParamsData, orderByField, orderDirection])

   useEffect(() => {
      const columnList = [...columns];
      if (ls_userType == 'user') {
         columnList.pop();
         // columnList.push({
         //    selector: (row) => <div style={{display:"flex", justifyContent:"space-between"}}>
         //       {row?.fileuploadname &&
         //          <Button onClick={() => handleDownloadFile(row?.fileuploadname_full_url, row?.fileuploadname?.split('.').pop())} type="primary" shape="circle" icon={<DownloadOutlined />} size={'middle'} />}

         //       {row?.fileuploadname_tor &&
         //       <>&nbsp;
         //          <Button onClick={() => handleDownloadFile(row?.fileuploadname_tor_full_url, row?.fileuploadname_tor?.split('.').pop())} type="primary" shape="circle" icon={<DownloadOutlined />} size={'middle'} />
         //       </>}
         //    </div>,
         //    name: "PDS / TOR",
         //    //   sortable: true,
         //    // center: true,
         //    width:'100px'
         //  });

          columnList.push({
            selector: (row) => <div>
               {row?.fileuploadname &&
               <Button onClick={() => handleDownloadFile(row?.fileuploadname_full_url, row?.fileuploadname?.split('.').pop())} type="primary" shape="circle" icon={<DownloadOutlined />} size={'middle'} />}
            </div>,
            name: "PDS",
            //   sortable: true,
            center: true,
            width:'65px'
          })

          columnList.push({
            selector: (row) => <div>
               {row?.fileuploadname_tor &&
               <Button onClick={() => handleDownloadFile(row?.fileuploadname_tor_full_url, row?.fileuploadname_tor?.split('.').pop())} type="primary" shape="circle" icon={<DownloadOutlined />} size={'middle'} />}
            </div>,
            name: "TOR",
            //   sortable: true,
            center: true,
            width:'65px'
          })
      }
      setColumnsLis(columnList);
   }, [ls_userType])
   
   
  return (
   <>
    <Breadcrumb parent="Project List" title=""   sideComponent={<div>
      {ls_userType == 'admin' ? <Link to={'/projects/create'}>
          <div style={{ display: 'flex' }}>
            <i className="fa fa-plus-circle" style={{ fontSize: 20, marginInlineEnd: 10 }}></i>
            <strong>Create Project</strong>
          </div>
       </Link> : <></>}
    </div>} 
      />
    <div className="col-xl-12 xl-100">

      <ProjectSearchForm advanceForm={advanceForm} handleSearchData={handleSearchData} lb_loading={loading} />

    <div className="card parent-tblsec">
      <div className="card-header card-header-border">
         <div className="row" style={{ alignItems: 'center' }}>
            <div className="col-sm-7">
               <h5>{"Project List"}</h5>
            </div>
            <div className="col-sm-5">
               {/* <div className="pull-right right-header">
                  <div className="onhover-dropdown">
                     <Search allowClear onChange={(e) => handleSearch(e.target.value)} placeholder="Search here..." />
                  </div>
               </div> */}
            </div>
         </div>
      </div>
      <div className="card-body">
          <div className="table-responsive">
              <DataTable
                  columns={la_columnsList}
                  data={la_projectList}
                  onSort={handleSort}
                  sortServer
                  striped={true}
                  center={true}
                  persistTableHead
                  pagination
                  paginationServer
                  paginationTotalRows={totalCount} // Assuming 10 rows per page
                  paginationPerPage={10} // Number of rows per page
                  paginationRowsPerPageOptions={[10, 20, 30, 40, 50]} // Optional: Rows per page options
                  paginationComponentOptions={{ noRowsPerPage: true }} // Hide rows per page dropdown
                  onChangePage={setCurrentPage} // Update currentPage when page changes
                  progressPending={loading}
                  progressComponent={<div><LoadingOutlined/></div>}
                  customStyles={{
                     headCells: {
                        style: {
                           paddingLeft: '8px', // Adjust cell padding as needed
                           paddingRight: '8px',
                        },
                     },
                     rows: {
                        style: {
                           minHeight: '40px', // Adjust row height as needed
                        
                        },
                     },
                     cells: {
                        style: {
                           paddingLeft: '8px', // Adjust cell padding as needed
                           paddingRight: '8px', // Adjust cell padding as needed
                     
                        },
                     },
                     }}
             />
          </div>
       </div>
    </div>
    </div>

    <ViewProjectDetails {...{lb_showProjectDetails, setShowProjectDetails, ls_projectId, setProjectId}}/>
    </>
  )
}

export default ProjectList
