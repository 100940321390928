import React, { useEffect, useState } from 'react';
import { Col, message, Modal, Row, Select } from 'antd';
import Form from "antd/lib/form";
import { useSelector } from 'react-redux';
import { Divider } from 'antd/lib';
import { getRequestApiByToken, postRequestApiByToken } from '../../../../api';

const ShareModal = ({ visible=false, onCancel=()=>{},ls_documentId=''}) => {
    const [form] = Form.useForm();
    const ls_userId = useSelector(content => content.commonReducer.user_id);
    const [la_userDetails, setUserDetails] = useState([])
    const [lb_loading, setLoading] = useState(false)

    const getAllUserList = async () => {
        const res = await getRequestApiByToken('users')
        const filteredUser = res?.Users?.filter(user => (user.id != ls_userId && user?.status == 1));
        setUserDetails(filteredUser || [])
      };

      useEffect(() => {
        getAllUserList()
      }, [])

      const handleOnOk =async (value)=>{
        let lo_formData = {
            document_id: ls_documentId,
            to_user_id: value.user_id
        }
        setLoading(true)
        const res = await postRequestApiByToken('shareotherdocument', lo_formData)
        if (res?.status =="Success") {
            message.success(res?.message);
            onCancel()
            form.resetFields();
        }
        setLoading(false)
      }

    return (
    <Modal
      title={"Share Document"}
      visible={visible}
      onCancel={onCancel}
        // footer={null}
        onOk={() => {
            form.validateFields().then((values) => {
                // form.resetFields();
                handleOnOk(values);
            })
                .catch((info) => {
                    // console.log('Validate Failed:', info);
                });
        }
        }
        confirmLoading={lb_loading}
    >
      <Divider/>
      <Form form={form}
                className='mt8-xs'
                labelCol={{
                    span: 8,
                }}
                wrapperCol={{ span: 16 }} 
            >
        <Row>
            <Col span={24}>
            <Form.Item
                    label="Select User"
                    name="user_id"
                    rules={[{ required: true,  message: "Please input User!"}]}
                >
                    <Select placeholder="Select User"
                    //   onChange={(value, event) => {handleSetUserId(value)}}
                      allowClear showSearch
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                      option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                      filterSort={(optionA, optionB) =>
                      optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                      }
                      style={{width:'100%'}}
                    >
                      {la_userDetails?.map(rowValue => (
                          <Select.Option key={rowValue.id} value={rowValue.id} subcategory={rowValue?.children || []}>
                              {`${rowValue.name} (${rowValue?.email})`}
                          </Select.Option>
                      ))}
                    </Select>
                  
                </Form.Item>
            </Col>
       </Row> 
       </Form>            
    </Modal>
  );
};

export default ShareModal;
